<template>
  <v-popover
    ref="popover"
    popoverClass="popover-edit-Intervenants"
    :placement="placement"
    :disabled="!canEdit"
    offset="0px"
    :handleResize="true"
  >
    <slot></slot>
    <template slot="popover">
      <div class="tooltip-edit-content">
        <div class="surface">
          <label class="label"
            >Commercial sédentaire :<span class="notBold">{{
              lead ? lead.title_agent : '-'
            }}</span></label
          >
        </div>
        <div class="surface">
          <label class="label"
            >Pré-visiteur :
            <span class="notBold">{{
              lead ? lead.title_comm_ite : '-'
            }}</span></label
          >
        </div>
        <div class="surface">
          <label class="label"
            >Commercial terrain :
            <span class="notBold">{{
              lead ? lead.title_comm_terrain : '-'
            }}</span></label
          >
        </div>
        <div class="surface">
          <label class="label"
            >Resp.planning :<span class="notBold">
              {{ lead ? lead.title_resp_plan : '-' }}</span
            ></label
          >
        </div>
      </div>
    </template>
  </v-popover>
</template>

<script>
import Vue from 'vue'
import { VPopover } from 'v-tooltip'
import { mapActions } from 'vuex'

Vue.component('v-popover', VPopover)

export default {
  name: 'TooltipEditIntervenants',
  props: {
    placement: {
      type: String,
      default: 'auto'
    },
    canEdit: { default: false },
    lead: { required: true }
  },
  data() {
    return {}
  },
  methods: {
    ...mapActions(['updateFieldPose'])
  }
}
</script>

<style lang="scss">
.tooltip {
  &.popover-edit-Intervenants {
    opacity: 1;
    display: block !important;
    z-index: 10000;
    .tooltip-inner {
      .close-tooltip {
        position: absolute;
        top: 3px;
        right: 8px;
        color: #656565;
        cursor: pointer;
        &:hover {
          color: black;
        }
      }
      .form-group {
        margin: 0 16px 10px;
      }

      .tooltip-edit-content {
        padding: 10px 10px;
        .surface {
          display: flex;
          .label {
            text-align: start;
            font-weight: bold;
          }
          .notBold {
            margin-left: 5px;
            font-weight: normal;
          }
        }
      }
    }

    .tooltip-arrow {
      width: 0;
      height: 0;
      border-style: solid;
      position: absolute;
      margin: 5px;
      border-color: black;
      z-index: 1;

      border-color: black !important;
      background: white !important;
      border-width: 1px !important;
      border-left-color: rgba(0, 0, 0, 0.2) !important;
      border-right-color: rgba(0, 0, 0, 0.2) !important;
      border-top-color: rgba(0, 0, 0, 0.2) !important;
      border-bottom-color: rgba(0, 0, 0, 0.2) !important;

      width: 10px;
      height: 10px;
      z-index: -2;
      transform: rotateZ(45deg);
    }

    &[x-placement^='top'] {
      margin-bottom: 5px;

      .tooltip-arrow {
        border-width: 5px 5px 0 5px;
        border-left-color: transparent !important;
        border-right-color: transparent !important;
        border-bottom-color: transparent !important;
        bottom: -5px;
        left: calc(50% - 5px);
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    &[x-placement^='bottom'] {
      margin-top: 5px;

      .tooltip-arrow {
        top: -5px;
        left: calc(50% - 5px);
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    &[x-placement^='right'] {
      margin-left: 5px;

      .tooltip-arrow {
        left: -5px;
        top: calc(50% - 5px);
        margin-left: 0;
        margin-right: 0;
      }
    }

    &[x-placement^='left'] {
      margin-right: 5px;

      .tooltip-arrow {
        right: -5px;
        top: calc(50% - 5px);
        margin-left: 0;
        margin-right: 0;
      }
    }

    &.popover {
      $color: #f9f9f9;
      max-width: unset;
      border-radius: 0.1rem;
      .popover-inner {
        background: $color;
        color: black;
        border-radius: 5px;
        box-shadow: 0 5px 30px rgba(black, 0.1);
      }

      .popover-arrow {
        border-color: $color;
      }
    }

    &[aria-hidden='true'] {
      visibility: hidden;
      opacity: 0;
      transition: opacity 0.15s, visibility 0.15s;
    }

    &[aria-hidden='false'] {
      visibility: visible;
      opacity: 1;
      transition: opacity 0.15s;
    }
  }
}
</style>
